<template>
    <div >    
    <footer>
        <img src="../../public/image/BGWave.png" alt="" class="footerImage">
      <div class="footer-container">
        <img src="../../public/image/logo14.png" alt="logo" class="logo">
        <div class="footernav">
            <li class="navLink"><router-link to="/contact" >Contact Us</router-link></li>
            <li class="navLink"><router-link to="/about" >Get Started</router-link></li>
            <li class="navLink"><router-link to="/pricing" >About</router-link></li>
        </div>
      </div>
    </footer>
    <div class="footerbelow">
        <p>© Copyright 2022. All Rights Reserved. Powered By Cache Technology</p>
    </div>
    </div>
</template>
<style scoped>
html {
    scroll-behavior: smooth;
  }
 .scrollBtn{
    width: 40px;
    height: 40px;
    background-color:#3498db;
    border-radius: 50%;
    padding: 10px;
    position: fixed;
    bottom: 20px;
    left: 20px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #fff;
 }
 
.navItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
}

.navLink {
    color: #1a1e23;
    font-size: 16px;
    margin: 0 20px;
    list-style: none;
    display: flex;
    flex-direction: row;
}

.navLink a {
    text-decoration: none;
    color: #b8b8b8;
}

.navLink a:hover {
    color: #fff;
}
  footer {
    background-color: #2a3342;
    color: #fff;
    text-align: center;
    bottom: 0;
  }
  .footerImage{
    width: 100%;
    object-fit: cover;
    padding-top: 60px;
  }
  .footer-container{
    padding: 50px;
  }
  .footerbelow{
    background-color: #2a3342;
    color: #8896AB;
    border-top: 1px solid #333F51;
    text-align: center;
    padding: 50px;
    font-size: 12px;
  }
  .footernav{
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
</style>