<template>
  <div>

    <body>
      <div class="container-scroller">
        <WebsiteHeader />
        <div class="container-fluid page-body-wrapper">
          <div class="main-panel">
            <div class="content-wrapper">
              <div class="row">
                <div class="col-12 grid-margin stretch-card">
                  <div class="card">
                    <div class="card-body">
                      <router-view></router-view>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </body>
  </div>
</template>
<script>
import WebsiteHeader from '@/components/websiteHeader.vue'
import Footer from '@/components/footer.vue'
export default {
  components: {
    WebsiteHeader,
    Footer
  }
}
</script>
<style scoped>
@import '../../public/css/horizontal-layout-dark/style.css';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200&family=Roboto&display=swap');

.page-body-wrapper {
  padding-top: 0px;
  font-family: 'Roboto', sans-serif;
  background-color: #2a3342;
}

.card {
  background-color: #1e1e2f !important;
  box-shadow: none !important;
  border: none !important;
  padding: 0 !important;
  font-family: 'Roboto', sans-serif;
}

.card-body {
  padding: 0 !important;
  font-family: 'Roboto', sans-serif;
}

.content-wrapper {
  padding: 0 !important;
  font-family: 'Roboto', sans-serif;
  background-color: #2a3342;
}

.container-fluid {
  padding: 0 !important;
  font-family: 'Roboto', sans-serif;
  background-color: #2a3342;
}
</style>


